import { fetchParcoursBegin, fetchParcoursSuccess , fetchParcoursFailure } from './types'

const API = "https://api.parcours-client.arval.fr/api/v1/parcours";
const CACHE_NAME = API;

export const fetchParcours = (byslug) => {
    // if (isCached(CACHE_NAME)) {
    //     return dispatch => {
    //         dispatch(fetchParcoursBegin());
    //         let response = getUrlCache();
    //         response.slug = byslug;
    //         dispatch(fetchParcoursSuccess(response));
    //         //console.log('bonsoir');
    //         return response;
    //     }
    // } else {
        return dispatch => {
            dispatch(fetchParcoursBegin());
                return fetch(API
                   
            )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                json.slug = byslug;
                dispatch(fetchParcoursSuccess(json));
                //console.log(json);
                setCached(json);
                return json.parcours;
            })
            .catch(error => {
                dispatch(fetchParcoursFailure(error));
            })
        };
    //}
}

export function getUrlCache() {

    var localStorage
    try {
        localStorage = window.localStorage
        // Get the url cache from the localstorage, which is an array
        return ( localStorage.getItem(CACHE_NAME) ? JSON.parse(localStorage.getItem(CACHE_NAME)) : [] )
    } catch(e){
        console.log(e);
        // Make your own then...
        //throw "localStorage does not exist yo"
    }
}
//  function handleConnectionChange() {
//     const condition = navigator.onLine ? 'online' : 'offline';
//     if (condition === 'online') {
//       const webPing = setInterval(
//         () => {
//           fetch('//google.com', {
//             mode: 'no-cors',
//             })
//           .then(() => {
//             this.setState({ isDisconnected: false }, () => {
//               return clearInterval(webPing)
//             });
//           }).catch(() => this.setState({ isDisconnected: true }) )
//         }, 2000);
//       return;
//     }

//     return this.setState({ isDisconnected: true });
//   }


export function isCached(url) {

    window.addEventListener('online', function(item) {
        //fetchParcours();
        removeCached(CACHE_NAME);
    });
    window.addEventListener('offline', function(item) {
        //isConnected = false;
    });


    var urlCache = getUrlCache()
    if(urlCache.length > 0) { 
    return true
    } else {
        return false;
    }
}

export function setCached(data){ 
    // if( isCached(url) )
    //     return false

    // console.log(data);
    // var urlCache = getUrlCache()
    // urlCache.push(url)

    localStorage.setItem(CACHE_NAME, JSON.stringify(data))

    // return true
}

export function removeCached(url){
    var myCache = getUrlCache()
    var index = myCache.indexOf(url)
    if(index !== -1){
        myCache = myCache.splice(index, 1)
        localStorage.setItem(CACHE_NAME, myCache)
        return true
    } else {
        return false
    }
}

// export const fetchParcours = (byslug) => {
//     const headers = {
//         method: 'GET',
//         mode: 'no-cors',
//         'Access-Control-Allow-Origin': '*',
//         'Content-Type': 'application/json',
//     };
//     return dispatch => {
//         dispatch(fetchParcoursBegin());
//         axios(API, {
//             method: 'GET',
//             mode: 'no-cors',
//             headers: {
//             // 'Access-Control-Allow-Origin': '*',
//             'Content-Type': 'application/json',
//             'Access-Control-Allow-Credentials': 'true'
//         },
//         withCredentials: true,
//         credentials: 'same-origin',
//         })
//         .then(handleErrors)
//         .then(res => res.json())
//         .then(json => {
//             json.slug = byslug;
//             dispatch(fetchParcoursSuccess(json));
//             console.log("json : ",json);
//             return json.parcours;
//         })
//         .catch(function (error) {
//         // handle error
//         fetchParcoursFailure(error)
//         console.log(error);
//         })
//         .finally(function () {
//         // always executed
//         });
//     }
// }


function handleErrors(response){
    // console.log(response);
    if(response.ok !== true){
        throw Error(response.statusText);
    }
    return response;
}
